import React, { useEffect, useState } from 'react';
import styles from './Schedule.module.css';
import BackMenu from '../../components/BackMenu/BackMenu';
import AllGroups from '../../components/AllGroups/AllGroups'
import { fetchSchedule, fetchSaveSubject } from '../../http/scheduleApi';
import Loader from '../../components/Loader/Loader'
import RenderTable from '../../components/RenderTable/RenderTable'
import EditMode from '../../components/EditMode/EditMode';
import { useDispatch, useSelector } from 'react-redux';
import { setSchedule } from '../../reducers/scheduleReducer';
import BotButtons from '../../components/BotButtons/BotButtons';
import { useSnackbar } from 'notistack';

function Schedule() {
  const [isEditMode, setIsEditMode] = useState(false);
  const [openEditMode, setOpenEditMode] = useState(false);
  const [week, setWeek] = useState(null);

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();
  const { editedData } = useSelector(state => state.schedule);

  useEffect(() => {
    localStorage.getItem('lastSchedule') ?
      getNewInfo(localStorage.getItem('lastSchedule'))
      : getNewInfo('ИБ - 12');
  }, [])

  const getNewInfo = async (group) => {
    const data = await fetchSchedule(group);
    setWeek(data.nubmer_week);
    dispatch(setSchedule({ groupSubject: data.subjects, editedData: data.scheduleData, dates: data.date }));
    localStorage.setItem('lastSchedule', group);
  }

  const handleEditClick = async () => {
    setIsEditMode(!isEditMode);
  };

  const handleEditSave = async () => {
    setIsEditMode(!isEditMode);
    enqueueSnackbar('Расписание успешно сохранено!', { variant: 'success' });
  }

  const handleSave = async (updateData) => {
    const group = localStorage.getItem('lastSchedule') ?
      localStorage.getItem('lastSchedule') : 'ИБ - 12'

    await fetchSaveSubject(group, updateData);
  };

  return (
    <section className={styles.container}>
      <BackMenu />
      <div className={styles.content}>

        <header className={styles.title__wrapper}>
          <h2 className={styles.title}>Расписание ({localStorage.getItem('lastSchedule') ?
            localStorage.getItem('lastSchedule') : 'ИБ - 12'})</h2>
          <nav className={styles.button__wrapper}>

            <BotButtons />

            <hr className={styles.hr} />

            {isEditMode ?
              <button className={styles.button__cancel} onClick={() => handleEditSave()}>
                Сохранить
              </button> :
              <button className={styles.button} onClick={() => handleEditClick()}>
                Изменить
              </button>
            }
            <AllGroups getNewInfo={getNewInfo} />
          </nav>
        </header>

        {editedData ?
          (<div className={styles.schedule}>
            <RenderTable
              isEditMode={isEditMode}
              setOpenEditMode={setOpenEditMode}
            />
          </div>) : (
            <div className={styles.schedule__loading}>
              <div className={styles.loader}>
                <Loader />
              </div>
            </div>)}

        <div className={styles.schedule__info}>
          <p className={styles.schedule__info__title}>Текущая неделя: {week}</p>
          <p>Дата: {new Date().toLocaleDateString()}</p>
        </div>

        {openEditMode && <EditMode
          openEditMode={openEditMode}
          setOpenEditMode={setOpenEditMode}
          handleSave={handleSave}
          setIsEditMode={setIsEditMode}
        />}

      </div>

    </section >
  );
}

export default Schedule;