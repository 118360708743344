import React from 'react';
import { useDispatch } from 'react-redux';
import styles from './Students.module.css';
import { fetchJournalEstimation } from '../../http/journalApi'
import { setPosition, setLessonInfo } from '../../reducers/miniMenuReducer'

const Students = React.memo(({
    students,
    dates,
    estimation,
    highlightedRow,
    highlightedColumn,
    handleInputChange,
    handleCellClick,
    selectedIndex }) => {

    const dispatch = useDispatch();

    const handleContextMenu = (e, date, student) => {
        e.preventDefault();

        dispatch(setPosition({
            positionX: e.clientX + 20,
            positionY: e.clientY,
            visible: true
        }));

        dispatch(setLessonInfo({
            date: date.number,
            studentId: student._id,
            studentName: student.FIO
        }))
    };

    const journalEstimation = async (group, subject, date, grades, studentId) => {
        const data = await fetchJournalEstimation(group,
            subject, date.number,
            grades, studentId, date.number_lesson);

    }

    const inputClick = (studentIndex, dateIndex, date, student) => {

        if (selectedIndex) {
            journalEstimation(
                localStorage.getItem('lastSchedule') || 'ЩИКО - 01 - 23',
                localStorage.getItem('lastLesson') || 'Иностранный язык',
                date,
                selectedIndex,
                student._id,
            )
        }
        handleCellClick(studentIndex, dateIndex);
    }

    return (
        students.map((student, studentIndex) => (
            <tr key={studentIndex} className={styles.journal__row}>
                <td className={`${styles.td} ${styles.sticky__column} ${studentIndex === highlightedRow ? styles.highlightedRow : ''}`}>
                    <div className={styles.student__wrapper}>
                        <p className={styles.index}>{studentIndex + 1}.</p>
                        <p className={styles.name}>{student.FIO}</p>
                    </div>
                </td>

                {dates.map((date, dateIndex) => {

                    const studentData = students[studentIndex];
                    const studentDates = studentData;

                    const isHighlightedRow = studentIndex === highlightedRow;
                    const isHighlightedColumn = dateIndex === highlightedColumn;

                    const foundEstimation = estimation.find(
                        item => item.date === date.number && item.studentId === student._id
                    );

                    const cellValue = foundEstimation ? foundEstimation.grade : (studentDates && studentDates[dateIndex]) || '';

                    return (
                        <td
                            key={dateIndex}
                            className={styles.td}
                        >
                            <input
                                className={`${styles.cell} ${isHighlightedRow ? styles.highlightedRow : ''} ${isHighlightedColumn ? styles.highlightedColumn : ''}`}
                                type="text"
                                value={cellValue}
                                onContextMenu={(e) => handleContextMenu(e, date, student)}
                                onChange={(event) => {
                                    handleInputChange(event, studentIndex, dateIndex);
                                    journalEstimation(
                                        localStorage.getItem('lastSchedule') || 'ЩИКО - 01 - 23',
                                        localStorage.getItem('lastLesson') || 'Иностранный язык',
                                        date,
                                        event.target.value,
                                        student._id,
                                    );
                                }}
                                onClick={() => inputClick(studentIndex, dateIndex, date, student)}
                            />
                        </td>
                    );
                })}

                <td className={`${styles.td__end} ${studentIndex === highlightedRow ? styles.highlightedRow : ''}`}></td>
            </tr>
        ))
    );
});

export default Students;
