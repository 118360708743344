import React, { useEffect, useState } from 'react';
import { fetchAllJournal } from '../../http/journalApi';
import styles from './BigSchedule.module.css';
import Loader from '../../components/Loader/Loader';
import EditMode from '../../components/EditMode/EditMode';
import BackMenu from '../../components/BackMenu/BackMenu';
import { useDispatch } from 'react-redux';
import { setSchedule, setSelectedLesson } from '../../reducers/scheduleReducer';
import { fetchSaveSubject } from '../../http/scheduleApi';
import { useSnackbar } from 'notistack';

function BigSchedule() {

    const daysOfWeek = ['Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница'];

    const [scheduleList, setScheduleList] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(5);
    const [totalCount, setTotalCount] = useState(0);
    const [block, setBlock] = useState(false);
    const [fetching, setFetching] = useState(true);
    const [subjects, setSubjects] = useState([]);
    const [group, setGroup] = useState('');
    const [date, setDate] = useState(null);

    const [changeGroup, setChangeGroup] = useState('');
    const [openMode, setOpenMode] = useState(false);
    const [editMode, setEditMode] = useState(false);

    const [searchGroup, setSearchGroup] = useState('');
    const [searchTeacher, setSearchTeacher] = useState('');
    const [searchAuditory, setSearchAuditory] = useState('');

    const { enqueueSnackbar } = useSnackbar();

    const dispatch = useDispatch();

    useEffect(() => {
        if (fetching) {
            getInfo();
        }
    }, [fetching]);

    const getInfo = async () => {
        setFetching(true);
        try {
            const data = await fetchAllJournal(page, limit);
            setScheduleList(prevList => [...prevList, ...data.data]);
            setSubjects(data.subjects);
            setPage(prevPage => prevPage + 1);
            setTotalCount(data.total);
            setDate(data.date);
        } finally {
            setFetching(false);
        }
    };

    useEffect(() => {
        document.addEventListener('scroll', scrollHandler);

        return () => {
            document.removeEventListener('scroll', scrollHandler);
        };
    }, [scheduleList, totalCount, fetching]);

    const scrollHandler = (e) => {
        if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) < 100 && scheduleList.length < totalCount && !block) {
            setFetching(true);
        }
    };

    const searchHandler = async () => {
        const data = await fetchAllJournal(page, limit, searchGroup, searchTeacher, searchAuditory);
        setScheduleList(data.data);
        setBlock(true);
    }

    const reloadInfo = () => {
        setBlock(false);
        setScheduleList([]);
        setSearchGroup("");
        setSearchTeacher("");
        setSearchAuditory("");
        setPage(1);
        setFetching(true);
    }

    const handleSave = async (updateData) => {

        const index = scheduleList.findIndex(item => item.group === group);

        if (index !== -1) {
            const updatedScheduleList = [...scheduleList];
            updatedScheduleList[index] = {
                ...updatedScheduleList[index],
                scheduleData1: updateData.scheduleData1,
                scheduleData2: updateData.scheduleData2
            };

            setScheduleList(updatedScheduleList);
        }

        await fetchSaveSubject(group, updateData);
    };

    const handleEditMode = (subjects, index, lesson, day, week, group, lessonIndex) => {

        const scheduleData = {
            scheduleData1: scheduleList[index].scheduleData1,
            scheduleData2: scheduleList[index].scheduleData2
        }

        setGroup(group);
        dispatch(setSchedule({ groupSubject: subjects, editedData: scheduleData, dates: date }));
        dispatch(setSelectedLesson({ selectedLesson: { week: week, day: day, index: lessonIndex, value: lesson } }));

        setEditMode(!editMode);

    }

    const handleEditSave = async (item) => {
        setChangeGroup(item.group);
        setOpenMode(!openMode);

        if (openMode) {
            enqueueSnackbar('Данные успешно обновлены', { variant: 'success' });
        }
    }

    return (
        <section className={styles.main__wrapper}>
            <BackMenu />
            <div className={styles.big__schedule__container}>

                <header className={styles.container}>

                    <div className={styles.filters}>
                        <div className={styles.search}>
                            <div className={styles.search__wrapper}>
                                <label className={styles.search__label}>Поиск по группе</label>
                                <input className={styles.search__input} value={searchGroup} onChange={(e) => setSearchGroup(e.target.value)} type="text" placeholder="Номер группы" />
                            </div>
                        </div>

                        <div className={styles.search}>
                            <div className={styles.search__wrapper} >
                                <label className={styles.search__label}>Поиск по преподавателям</label>
                                <input className={styles.search__input} value={searchTeacher} onChange={(e) => setSearchTeacher(e.target.value)} type="text" placeholder="Имя преподавателя" />
                            </div>
                        </div>

                        <div className={styles.search}>
                            <div className={styles.search__wrapper}>
                                <label className={styles.search__label}>Поиск по кабинетам</label>
                                <input className={styles.search__input} value={searchAuditory} onChange={(e) => setSearchAuditory(e.target.value)} type="text" placeholder="Номер кабинета" />
                            </div>
                            <button className={styles.search__btn} type="button" onClick={searchHandler}>Поиск</button>
                        </div>
                    </div>

                    <div className={styles.delete}>
                        <button className={styles.delete__btn} type="button" onClick={() => reloadInfo()}>Сбросить фильтры</button>
                    </div>
                </header>

                <div className={styles.table}>
                    {scheduleList.length > 0 ? scheduleList.map((item, index) => (
                        item.group !== null && (
                            <div className={styles.table__group} key={index}>
                                <div className={styles.group}>
                                    <div className={styles.title__info}>
                                        <h2 className={styles.h2}>{item.group}</h2>
                                        <h2 className={styles.h2__week__2}>Первая неделя</h2>
                                        <button className={styles.button__change} type="button" onClick={() => handleEditSave(item)}>
                                            {openMode && changeGroup === item.group ? 'Сохранить' : 'Изменить'}</button>
                                    </div>
                                    <div className={styles.group__info}>
                                        <div className={styles.weeks}>

                                            {daysOfWeek.map((day, dayIndex) => (
                                                <div className={styles.week__info} key={dayIndex}>

                                                    <h3 className={styles.day__name}>
                                                        {day}
                                                    </h3>

                                                    <div className={styles.lessons}>
                                                        {item.scheduleData1[day].map((lesson, lessonIndex) => (
                                                            <div
                                                                className={`${styles.lesson__name} ${lesson.mistake === 1 ? styles.warning : lesson.mistake === 2 ? styles.danger : ''} ${openMode && changeGroup === item.group ? styles.change__mode__true : ''
                                                                    }`}
                                                                onClick={() => openMode && handleEditMode(subjects[index], index, lesson, day, 'scheduleData1', item.group, lessonIndex)}
                                                                key={lessonIndex}
                                                            >
                                                                <div>{lesson.name || ' '}</div>
                                                                <div>{lesson.teacher}</div>
                                                                <div>{lesson.room}</div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.group} key={index}>
                                    <div className={styles.title__info__week__2}>
                                        <h2 className={styles.h2__week__2}>Вторая неделя</h2>
                                    </div>
                                    <div className={styles.group__info}>
                                        <div className={styles.weeks}>

                                            {daysOfWeek.map((day, dayIndex) => (
                                                <div className={styles.week__info} key={dayIndex}>

                                                    <h3 className={styles.day__name}>
                                                        {day}
                                                    </h3>

                                                    <div className={styles.lessons}>
                                                        {item.scheduleData2[day].map((lesson, lessonIndex) => (
                                                            <div
                                                                className={`${styles.lesson__name} ${lesson.mistake === 1 ? styles.warning : lesson.mistake === 2 ? styles.danger : ''} ${openMode && changeGroup === item.group ? styles.change__mode__true : ''
                                                                    }`}
                                                                onClick={() => openMode && handleEditMode(subjects[index], index, lesson, day, 'scheduleData2', item.group, lessonIndex)}
                                                                key={lessonIndex}
                                                            >
                                                                <div>{lesson.name || ' '}</div>
                                                                <div>{lesson.teacher}</div>
                                                                <div>{lesson.room}</div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            ))}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    )) : <div className={styles.loader}>
                        <Loader />
                    </div>}
                </div>

                {editMode && <EditMode openEditMode={editMode} setOpenEditMode={setEditMode} handleSave={handleSave} />}

            </div >
        </section >
    );
}

export default BigSchedule;
