import { $host, $authHost } from "./index";

export const getScheduleMonth = async (group, month) => {
  try {
    const { data } = await $authHost.post('/kpk/schedule_month', {
      group: group,
      month: month
    });
    return data;
  } catch (error) {
    console.error(error);
  }
}

export const fetchCalendarInfo = async (group, subject) => {
  try {
    const { data } = await $authHost.post('kpk/year_schedule', {
      group: group,
      subject: subject
    });
    return data;
  } catch (error) {
    console.error(error);
  }
}

export const themeList = async (number_lesson, lesson) => {
  try {
    const { data } = await $authHost.post('kpk/get_themas', {
      number_lesson: number_lesson,
      lesson: lesson
    });
    return data;
  } catch (error) {
    console.error(error);
  }
}

export const saveThemeInfo = async (subject, number_lesson, theme, type) => {
  try {
    const { data } = await $authHost.post('kpk/add_KTP', {
      subject: subject,
      number_lesson: number_lesson,
      theme: theme,
      type: type
    });
    return data;
  } catch (error) {
    console.error(error);
  }
}