import React, { useState, useEffect, useCallback, useRef } from 'react';
import BackMenu from '../../components/BackMenu/BackMenu';
import styles from './Journal.module.css';
import AllGroups from '../../components/AllGroups/AllGroups'
import QuickCast from '../../components/QuickCast/QuickCast'
import Students from '../../components/Students/Students'
import { getJournalUsers } from '../../http/journalApi'
import { useSelector } from 'react-redux';
import generateDatesForMonth from '../../components/generateDatesForMonth'
import Loader from '../../components/Loader/Loader'
import SelectLesson from '../../components/SelectLesson/SelectLesson'
import MiniMenu from '../../components/MiniMenu/MiniMenu'
import { updateEstimation } from '../../components/handleEstimationUpdate';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import KTPAddon from '../../components/KTPAddon/KTPAddon';

function Journal() {
    const [students, setStudents] = useState([]);
    const [lessonName, setLessonName] = useState(localStorage.getItem('lastLesson') || "Иностранный язык");
    const [dates, setDates] = useState([]);
    const [highlightedRow, setHighlightedRow] = useState(null);
    const [highlightedColumn, setHighlightedColumn] = useState(null);
    const [listOpen, setListOpen] = useState(false);
    const [group, setGroup] = useState();
    const [estimation, setEstimation] = useState();
    const [openKTP, setOpenKTP] = useState(false);

    const [scrollDirection, setScrollDirection] = useState(null);
    const journalTableRef = useRef(null);

    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [userPosition, setUserPosition] = useState({ start: false, end: false });

    const [selectedIndex, setSelectedIndex] = useState('');
    const profileData = useSelector(state => state.user);


    useEffect(() => {
        getNewInfo(localStorage.getItem('lastSchedule') || 'ИБ - 12', lessonName);
    }, [lessonName, currentMonth]);

    useEffect(() => {
        if (!scrollDirection) return;

        const element = journalTableRef.current;
        if (!element) return;

        // const totalWidth = element.scrollWidth;

        const interval = setInterval(() => {
            const previousScrollLeft = element.scrollLeft;
            element.scrollLeft += scrollDirection === 'right' ? 1 : -1;
            if (previousScrollLeft === element.scrollLeft) {
                clearInterval(interval);
                return;
            }
            // setUserPosition(currentPosition => {
            //     const atStart = element.scrollLeft === 0;
            //     const atEnd = Math.round(element.scrollLeft + element.clientWidth) >= totalWidth;
            //     if (atStart === currentPosition.start && atEnd === currentPosition.end) {
            //         return currentPosition;
            //     }
            //     return { start: atStart, end: atEnd };
            // });
        }, 1);

        return () => clearInterval(interval);
    }, [scrollDirection]);

    const getNewInfo = async (group, lessonName) => {
        const data = await getJournalUsers(localStorage.getItem('lastSchedule') || group, localStorage.getItem('lastLesson') || lessonName, currentMonth.toLocaleDateString('ru-RU'));

        setGroup(group);
        setStudents(data.Students);
        generateDatesForMonth(data.Dates_of_the_subject, setDates);
        setEstimation(data.Journal);
        localStorage.setItem('lastSchedule', group);
    }

    const handleCellClick = useCallback((studentIndex, dateIndex) => {

        setHighlightedColumn(dateIndex);
        setHighlightedRow(studentIndex);

        if (selectedIndex) {
            updateEstimation(estimation, students, dates, selectedIndex, setEstimation, studentIndex,
                dateIndex, null, true);
        }
    }, [dates, estimation, selectedIndex, setEstimation, setHighlightedRow, setHighlightedColumn, students]);

    const handleInputChange = useCallback((event, studentIndex, dateIndex) => {
        const { value } = event.target;
        updateEstimation(estimation, students, dates, selectedIndex, setEstimation, studentIndex, dateIndex, value, false);
    }, [dates, estimation, selectedIndex, setEstimation, students]);

    return (
        <div className={styles.container}>
            <BackMenu />
            <MiniMenu students={students.length} />
            <div className={styles.journal__wrapper}>
                <div className={styles.all__groups__wrapper}>
                    <h2 className={styles.journal__title}>Журнал ({localStorage.getItem('lastSchedule') || 'ИБ - 12'})</h2>
                    <div className={styles.quick__cast}>
                        <QuickCast setSelectedIndex={setSelectedIndex} openKTP={openKTP} setOpenKTP={setOpenKTP} />
                        <AllGroups getNewInfo={getNewInfo} />
                    </div>
                </div>

                <div className={styles.journal__content__wrapper}>
                    <div className={`${dates.length !== 0 ? styles.journal__content : styles.journal__content__loading} ${openKTP ? styles.journal__content__active : ''}`}>

                        <SelectLesson setLessonName={setLessonName}
                            listOpen={listOpen} setListOpen={setListOpen}
                            lessonName={lessonName} group={group}
                            setCurrentMonth={setCurrentMonth} currentMonth={currentMonth} openKTP={openKTP} />

                        {dates.length !== 0 ? (
                            <div className={styles.journal__table__wrapper}>
                                <div className={styles.left__trigger}
                                    onMouseEnter={() => setScrollDirection('left')}
                                    onMouseLeave={() => setScrollDirection(null)}><IoIosArrowBack className={styles.navButton} /></div>
                                <div className={styles.journal__table__overflow} ref={journalTableRef}>
                                    <table className={styles.journal__table}>

                                        <thead className={styles.journal__header}>
                                            <tr>
                                                <th className={styles.th}>Студент</th>
                                                {dates.map((date, index) => (
                                                    <th key={index} className={styles.th}>
                                                        {date.number}
                                                    </th>
                                                ))}
                                                <th className={styles.th__end}></th>
                                            </tr>
                                        </thead>

                                        <tbody className={styles.journal__body}>
                                            <Students
                                                students={students}
                                                estimation={estimation}
                                                dates={dates}
                                                highlightedRow={highlightedRow}
                                                highlightedColumn={highlightedColumn}
                                                handleInputChange={handleInputChange}
                                                handleCellClick={handleCellClick}
                                                selectedIndex={selectedIndex}
                                            />
                                        </tbody>
                                    </table>
                                </div>
                                <div className={styles.right__trigger}
                                    onMouseEnter={() => setScrollDirection('right')}
                                    onMouseLeave={() => setScrollDirection(null)}><IoIosArrowForward className={styles.navButton} /></div>
                            </div>
                        ) : (
                            <div className={styles.loader}>
                                <Loader />
                            </div>
                        )}
                    </div>

                    {openKTP && <KTPAddon lessonName={lessonName} currentMonth={currentMonth} />}

                </div>

                <div className={styles.journal__total__wrapper}>
                    <p className={styles.journal__total}>Всего студентов: {students.length}</p>
                    <p className={styles.journal__total}>Преподаватель:{profileData.firstName} {profileData.lastName}</p>
                </div>
            </div>
        </div >
    );
}

export default React.memo(Journal);
