import { $host, $authHost } from "./index";

export const fetchLessons = async (group, email) => {
    try {
        const { data } = await $authHost.get('kpk/subjects', {
            params: {
                group: group,
                email: email
            }
        });
        return data;
    } catch (error) {
        console.error("Failed to fetch lessons:", error);
    }
}