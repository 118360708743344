import React from 'react';
import styles from './BackMenu.module.css';
import compas from '../../assets/logo.png';
import { IoSettingsOutline } from "react-icons/io5";
import { FaUserCircle } from 'react-icons/fa';
import { useNavigate, useLocation, NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

const menuItems = [
    { ru: 'Профиль', en: 'profile', role: 1 },
    { ru: 'Расписание ст.', en: 'schedule', role: 2 },
    { ru: 'Расписание пд.', en: 'schedule-teacher', role: 1 },
    { ru: 'Расписание полн.', en: 'big-schedule', role: 2 },
    { ru: 'Журнал', en: 'journal', role: 1 },
    { ru: 'КТП', en: 'ktp', role: 1 },
    { ru: 'Преподаватели', en: 'teachers', role: 3 },
    { ru: 'Группы', en: 'groups', role: 3 },
    { ru: 'Статистика', en: 'statistics', role: 3 },
    { ru: 'Логирование', en: 'logs', role: 3 },
];

function BackMenu() {

    const profileData = useSelector(state => state.user);
    const navigate = useNavigate();
    const location = useLocation();

    const handleItemClick = (item) => {
        navigate(`/${item.en}`);
    };

    return (
        <aside className={styles.sidebar}>
            <div className={styles.sidebar__header}>
                <img className={styles.compas} src={compas} alt="" />
            </div>

            <ul className={styles.menu__list}>
                {menuItems.map((item) => {
                    if (item.en === 'schedule-teacher' && profileData.role === 3) {
                        return null;
                    }

                    return profileData.role < item.role ? null : (
                        <li
                            key={item.ru}
                            className={`${styles.menu__item} ${location.pathname === `/${item.en}` ? styles.active : ''}`}
                            onClick={() => handleItemClick(item)}
                        >
                            {item.ru}
                        </li>
                    );
                })}
            </ul>

            <div className={styles.sidebar__profile}>
                <div className={styles.sidebar__profile__info__beta}>
                    {/* <NavLink className={styles.sidebar__profile__info__beta__link} to={'/updates'}>Патч 1.12</NavLink> */}
                    <a className={styles.sidebar__profile__info__beta__link}>Патч 1.14</a>

                    <div className={styles.sidebar__profile__info__beta__settings}>
                        <IoSettingsOutline className={styles.sidebar__profile__info__beta__settings__icon} />
                        <a className={styles.feedback} href='https://t.me/kopoh_2'>Обратная связь</a>
                    </div>
                </div>
                <div className={styles.sidebar__profile__info}>
                    <FaUserCircle className={styles.sidebar__profile__info__icon} />
                    <p className={styles.sidebar__profile__info__name}>{profileData.firstName} {profileData.lastName}</p>
                </div>
            </div>
        </aside>
    );
}

export default BackMenu;
