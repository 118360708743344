import { $host, $authHost } from "./index";

export const fetchAllGroups = async () => {
    try {
        const { data } = await $authHost.get('kpk/groups');
        return data;
    } catch (error) {
        console.error("Failed to fetch AllGroups:", error);
    }
}

export const fetchSchedule = async (group) => {
    try {
        const { data } = await $authHost.get('kpk/schedule', {
            params: {
                group: group
            }
        });
        return data;
    } catch (error) {
        console.error("Failed to fetch Schedule:", error);
    }
}

export const fetchSubjects = async (group) => {
    try {
        const { data } = await $authHost.get('kpk/subjects', {
            params: {
                group: group
            }
        });
        return data;
    } catch (error) {
        console.error("Failed to fetch Subjects:", error);
    }
}


export const fetchSaveSubject = async (group, editedData) => {
    try {
        const { data } = await $authHost.post('kpk/update_subject', {
            group: group,
            editedData: editedData
        });
        return data;
    } catch (error) {
        console.error("Failed to fetch Subjects:", error);
    }
}

export const getScheduleTeacher = async (name) => {
    try {
        const { data } = await $authHost.get('kpk/teacher_schedule', {
            params: {
                teacher_name: name
            }
        });
        return data;
    } catch (error) {
        console.error("Failed to fetch ScheduleTeacher:", error);
    }
}

export const sendScheduleBot = async (formData) => {
    try {
        const { data } = await $authHost.post('kpk/send_schedule_bot', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return data;
    } catch (error) {
        console.error("Failed to fetch Subjects:", error);
    }
}