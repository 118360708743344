import React, { useEffect, useState } from 'react';
import styles from './ScheduleTeacher.module.css';
import BackMenu from '../../components/BackMenu/BackMenu';
import AllGroups from '../../components/AllGroups/AllGroups'
import { fetchSchedule, fetchSaveSubject, getScheduleTeacher } from '../../http/scheduleApi';
import Loader from '../../components/Loader/Loader'
import RenderTable from '../../components/RenderTable/RenderTable'
import EditMode from '../../components/EditMode/EditMode';
import { useDispatch, useSelector } from 'react-redux';
import { setSchedule } from '../../reducers/scheduleReducer';

function Schedule() {
    const [isEditMode, setIsEditMode] = useState(false);
    const [weekNum, setWeekNum] = useState(1);
    const [snackbar, setSnackbar] = useState({ show: false, text: '', status: '' });
    const [openEditMode, setOpenEditMode] = useState(false);

    const dispatch = useDispatch();

    const { editedData } = useSelector(state => state.schedule);

    const { shortName } = useSelector(state => state.user);

    useEffect(() => {
        if (shortName !== '') {
            localStorage.getItem('lastSchedule') ?
                getNewInfo(shortName)
                : getNewInfo(shortName);
        }
    }, [shortName])

    const getNewInfo = async (name) => {
        const data = await getScheduleTeacher(name);
        setWeekNum(data.week_number);

        dispatch(setSchedule({ groupSubject: data.subjects, editedData: data.scheduleData, dates: data.date }));
    }

    return (
        <div className={styles.container}>
            <BackMenu />
            <div className={styles.content}>

                <div className={styles.title__wrapper}>
                    <h2 className={styles.title}>Расписание преподавателя</h2>
                </div>

                {editedData ?
                    (<div className={styles.schedule}>
                        <RenderTable
                            isEditMode={isEditMode}
                            setOpenEditMode={setOpenEditMode}
                        />
                    </div>) : (
                        <div className={styles.schedule__loading}>
                            <div className={styles.loader}>
                                <Loader />
                            </div>
                        </div>)}

                <div className={styles.schedule__info}>
                    <p className={styles.schedule__info__title}>Текущая неделя: {weekNum}</p>
                    <p>Дата: {new Date().toLocaleDateString()}</p>
                </div>

                {openEditMode && <EditMode
                    openEditMode={openEditMode}
                    setOpenEditMode={setOpenEditMode}
                    setIsEditMode={setIsEditMode}
                />}

            </div>

        </div>
    );
}

export default Schedule;