import React, { useState } from 'react';
import BackMenu from '../../components/BackMenu/BackMenu';
import Calendar from '../../components/Calendar/Calendar'
import KTPList from '../../components/KTPList/KTPList'
import styles from './KTP.module.css';
import GetKTPLesson from '../../components/GetKTPLesson/GetKTPLesson';
import AllGroups from '../../components/AllGroups/AllGroups';

function KTP() {

  const [date, setDate] = useState();
  const [lessonNumber, setLessonNumber] = useState();
  const [calendarInfo, setCalendarInfo] = useState([]);
  const [currentLesson, setCurrentLesson] = useState(null);
  const [currentGroup, setCurrentGroup] = useState(null);

  const getNewInfo = async (group) => {
    setCurrentGroup(group);
  }

  return (
    <div className={styles.container}>
      <BackMenu />
      <div className={styles.content__wrapper}>
        <nav className={styles.nav}>
          <h2 className={styles.title}>КАЛЕНДАРНО - ТЕМАТИЧЕСКИЙ ПЛАН ({localStorage.getItem('lastSchedule') || 'ИБ - 12'})</h2>
          <div className={styles.nav__content}>
            <GetKTPLesson currentLesson={currentLesson} setCurrentLesson={setCurrentLesson} />
            <AllGroups getNewInfo={getNewInfo} />
          </div>
        </nav>

        <div className={styles.content}>
          <KTPList
            lessonNumber={lessonNumber}
            calendarInfo={calendarInfo}
            setCalendarInfo={setCalendarInfo}
            date={date}
            currentLesson={currentLesson} />

          <Calendar
            calendarInfo={calendarInfo}
            setCalendarInfo={setCalendarInfo}
            setLessonNumber={setLessonNumber}
            setDate={setDate}
            currentLesson={currentLesson}
            currentGroup={currentGroup} />
        </div>

        {
          !lessonNumber && <div className={styles.info}>Если данные не отображаются, пожалуйста, выберите день, над которым виден подсвеченный кружок.</div>
        }

      </div>
    </div>
  );
}

export default KTP;
