import React, { useState } from 'react'
import styles from './Groups.module.css'
import BackMenu from '../../components/BackMenu/BackMenu';
import AllGroups from '../../components/AllGroups/AllGroups'
import GroupTable from '../../components/GroupTable/GroupTable'

function Groups() {

    const [group, setGroup] = useState(null);

    const getNewInfo = (group, lessonName) => {
        setGroup(group);
    }

    return (
        <section className={styles.container}>
            <BackMenu />
            <div className={styles.content}>
                <header className={styles.header__content}>
                    <h2 className={styles.title}>Группы ({group || localStorage.getItem('lastSchedule') || 'ИБ - 12'})</h2>
                    <AllGroups getNewInfo={getNewInfo} />
                </header>

                <GroupTable group={group} />
            </div>
        </section>
    )
}

export default Groups