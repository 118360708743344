import { $host, $authHost } from "./index";

export const fetchUser = async (login, password, remember) => {
    try {
        const { data } = await $host.post(`kpk/login`, {
            login: login,
            password: password,
            remember: remember
        });
        return data;
    } catch (error) {
        console.error("Failed to fetch profile:", error);
    }
}

export const fetchProfile = async (id) => {
    try {
        const { data } = await $authHost.get(`kpk/profile_info`, {
            params: {
                user_id: id
            }
        });
        return data;
    } catch (error) {
        console.error("Failed to fetch profile:", error);
    }
}

export const fetchProfileToStart = async () => {
    try {
        const { data } = await $authHost.get(`/kpk/profile_info_token`);
        return data;
    } catch (error) {
        console.error("Failed to fetch profile:", error);
    }
}

export const updateProfile = async (editedProfile) => {
    try {
        const { data } = await $authHost.post(`/kpk/profile_change`, { editedProfile });
        return data;
    } catch (error) {
        console.error("Failed to fetch profile:", error);
    }
}

export const fetchAllGroups = async () => {

}