const SET_SCHEDULE_INFO = 'SET_SCHEDULE_INFO';
const SET_SELECTED_LESSON = 'SET_SELECTED_LESSON';
const SET_EDITED_DATA = 'SET_EDITED_DATA';

const initialState = {
    groupSubject: null,
    editedData: null,
    dates: null,
    selectedLesson: ''
};

export default function scheduleReducer(state = initialState, action) {

    switch (action.type) {
        case SET_SCHEDULE_INFO:

            const { groupSubject, editedData, dates } = action.payload

            return {
                ...state,
                groupSubject: groupSubject,
                editedData: editedData,
                dates: dates
            };

        case SET_SELECTED_LESSON:

            const { selectedLesson } = action.payload

            return {
                ...state,
                selectedLesson: selectedLesson
            }
        case SET_EDITED_DATA:

            const { updateData } = action.payload

            return {
                ...state,
                editedData: updateData
            }
        default:
            return state;
    }

};

export const setSchedule = (payload) => ({ type: SET_SCHEDULE_INFO, payload });
export const setSelectedLesson = (payload) => ({ type: SET_SELECTED_LESSON, payload });
export const setEditedData = (payload) => ({ type: SET_EDITED_DATA, payload })