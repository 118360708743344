import React, { useEffect, useState } from 'react';
import styles from './TeacherTable.module.css';
import { getTeachers } from '../../http/groupApi';
import Loader from '../../components/Loader/Loader'

function TeacherTable() {

    const [teachers, setTeachers] = useState();

    useEffect(() => {
        getInfo();
    }, [])

    const getInfo = async () => {
        const data = await getTeachers();
        setTeachers(data.teachers);
    }

    return (
        <div className={styles.groupTable__wrapper}>
            {teachers ? <div className={styles.tableContainer}>
                <table className={styles.groupTable}>
                    <thead>
                        <tr>
                            <th className={styles.th}>Преподаватели</th>
                        </tr>
                    </thead>
                    <tbody>
                        {teachers.map((teachers, teacherIndex) => (
                            <tr key={teacherIndex}>
                                <td className={styles.td}>
                                    <div className={styles.studentInfo}>
                                        <p className={styles.teacherIndex}>{teacherIndex + 1}.</p>
                                        <p className={styles.fio}>{teachers.fullName}</p>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div> :
                <div className={styles.loader}>
                    <Loader />
                </div>}
        </div>
    );
}

export default TeacherTable;
