import React, { useEffect, useState } from 'react';
import styles from './GroupTable.module.css';
import { getGroup } from '../../http/groupApi';
import Loader from '../../components/Loader/Loader'
function GroupTable({ group }) {

  const [students, setStudents] = useState();

  useEffect(() => {
    getInfo(group || localStorage.getItem('lastSchedule'), 'ИБ - 12');
  }, [group])

  const getInfo = async (group) => {
    const data = await getGroup(group);
    setStudents(data.students);
  }

  return (
    <div className={styles.groupTable__wrapper}>
      {students ? <div className={styles.tableContainer}>
        <table className={styles.groupTable}>
          <thead>
            <tr>
              <th className={styles.th}>Студенты</th>
            </tr>
          </thead>
          <tbody>
            {students.map((student, studentIndex) => (
              <tr key={studentIndex}>
                <td className={styles.td}>
                  <div className={styles.studentInfo}>
                    <p className={styles.studentIndex}>{studentIndex + 1}.</p>
                    <p className={styles.fio}>{student.FIO}</p>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div> :
        <div className={styles.loader}>
          <Loader />
        </div>}
    </div>
  );
}

export default GroupTable;
