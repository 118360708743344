import React from 'react';
import { motion } from 'framer-motion';
import styles from './MonthTitle.module.css'

const MonthTitle = ({ currentMonthKey, currentMonth, animateDirection }) => {
    return (
        <motion.h2
            key={currentMonthKey}
            className={styles.monthTitle}
            initial={{ opacity: 0, x: animateDirection === "forward" ? -20 : 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
        >
            {currentMonth.toLocaleString('default', { month: 'long', year: 'numeric' })}
        </motion.h2>
    );
};
export default MonthTitle;
