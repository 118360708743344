import React, { useState } from 'react';
import styles from './RenderTable.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedLesson } from '../../reducers/scheduleReducer';

function RenderTable({ setOpenEditMode, isEditMode }) {

    const daysOfWeek = ['Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница'];
    const dispatch = useDispatch();
    const { editedData, dates } = useSelector(state => state.schedule);

    const renderTable = (week, weekIndex) => {

        const handleCellChange = (week, day, index, value) => {

            if (isEditMode) {
                dispatch(setSelectedLesson({ selectedLesson: { week, day, index, value } }));
                setOpenEditMode(true);
            }

        };

        return (
            <div className={styles.scheduleTableWrapper}>
                <table className={styles.scheduleTable}>
                    <thead>
                        <tr className={styles.tableRaw}>
                            {daysOfWeek.map(day => (
                                <th key={day}>{day} ({dates[weekIndex][day]})</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {Array.from({ length: Math.max(...daysOfWeek.map(day => editedData[week][day].length)) }, (_, index) => (
                            <tr className={styles.tableRaw} key={index}>
                                {daysOfWeek.map((day) => {
                                    const cellValue = editedData[week][day][index];

                                    return (
                                        <td className={isEditMode ? styles.tableCell__edit : cellValue.mistake === 1 ? styles.tableCell__warning : cellValue.mistake === 2 ? styles.tableCell__danger : styles.tableCell} key={day}
                                            onClick={() => handleCellChange(week, day, index, cellValue)}>
                                            {[cellValue?.group, cellValue.name, cellValue.room, cellValue.teacher].map((value, idx) => (
                                                value !== undefined && value !== '' &&
                                                (
                                                    <React.Fragment key={idx}>
                                                        {value}
                                                        < br />
                                                    </React.Fragment>
                                                )
                                            ))}
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };

    return (
        <div className={styles.schedule__render__wrapper}>
            <h3 className={styles.scheduleTable__title}>Первая неделя</h3>
            {renderTable('scheduleData1', 'date1')}

            <h3 className={styles.scheduleTable__title}>Вторая неделя</h3>
            {renderTable('scheduleData2', 'date2')}
        </div>
    );
}

export default RenderTable;