import React from 'react'
import styles from './Teachers.module.css'
import BackMenu from '../../components/BackMenu/BackMenu';
// import AllGroups from '../../components/AllGroups/AllGroups'
import TeacherTable from '../../components/TeacherTable/TeacherTable'

function Teachers() {

    return (
        <section className={styles.container}>
            <BackMenu />
            <div className={styles.content}>
                <header className={styles.header__content}>
                    <h2 className={styles.title}>Преподаватели</h2>
                </header>

                <TeacherTable />
            </div>
        </section>
    )
}

export default Teachers