const defaultState = {
    user_id: '',
    lastName: '',
    firstName: '',
    middleName: '',
    shortName: '',
    phone: '',
    email: '',
    country: '',
    city: '',
    bio: '',
    photo: '',
    group: '',
    password: '**********',
    role: '',
    subjects: []
}

export default function userReducer(state = defaultState, action) {

    switch (action.type) {
        case 'SET_USER':

            const { user_id, photo, city,
                lastName, firstName, middleName,
                phone, email, country,
                bio, group, shortName,
                role, subjects } = action.payload

            return {
                ...state,
                bio: bio,
                city: city,
                country: country,
                email: email,
                firstName: firstName,
                group: group,
                lastName: lastName,
                middleName: middleName,
                phone: phone,
                photo: photo,
                user_id: user_id,
                shortName: shortName,
                role: role,
                subjects: subjects
            }
        case 'SET_ID':

            return {
                ...state,
                user_id: action.payload
            }

        default:
            return state
    }
}

export const setUser = info => ({ type: 'SET_USER', payload: info });
export const setId = id => ({ type: 'SET_ID', payload: id })