const defaultState = {
    allGroups: null
}

export default function groupReducer(state = defaultState, action) {

    switch (action.type) {
        case 'SET_GROUP':

            const data = action.payload

            return {
                ...state,
                allGroups: data
            }

        default:
            return state
    }
}

export const setGroup = info => ({ type: 'SET_GROUP', payload: info });