import React, { useEffect, useState } from 'react';
import styles from './KTPList.module.css';
import { motion } from 'framer-motion';
import Loader from '../Loader/Loader';
import { saveThemeInfo, themeList } from '../../http/KTPApi';

const formVariants = {
    hidden: {
        opacity: 0,
        scale: 0.95
    },
    visible: {
        opacity: 1,
        scale: 1,
        transition: {
            duration: 0.3
        }
    }
};

function KTPList({ lessonNumber, date, calendarInfo, setCalendarInfo, currentLesson }) {
    const [themes, setThemes] = useState([]);
    const [showForm, setShowForm] = useState(null);
    const [type, setType] = useState(null);
    const [acitveTheme, setActiveTheme] = useState(false);
    const [isEditing, setIsEditing] = useState(Array(themes.length).fill(false));

    useEffect(() => {
        if (lessonNumber) {
            getThemes();
        }
    }, [lessonNumber, currentLesson]);

    const getThemes = async () => {
        const data = await themeList(
            lessonNumber,
            currentLesson || localStorage.getItem('lastLesson')
        );
        setThemes([data]);

        if (data.type) {
            setType(data.type);
        } else {
            setType('Не указано');
        }
    }

    const toggleForm = (index) => {
        const updatedIsEditing = isEditing.slice();
        updatedIsEditing[index] = !updatedIsEditing[index];
        setIsEditing(updatedIsEditing);

        if (isEditing[index]) {
            saveTheme(index);
        }

        if (showForm === index) {
            setShowForm(null);
        } else {
            setShowForm(index);
        }
    };

    const handleThemeChange = (index, newTheme) => {
        const updatedLessons = themes.map((lesson, i) => {
            if (i === index) {
                return { ...lesson, theme: newTheme };
            }
            return lesson;
        });
        setThemes(updatedLessons);
    };

    const saveTheme = async (lessonIndex) => {
        const updatedCalendarInfo = [...calendarInfo];
        const lessonIndexInCalendar = updatedCalendarInfo.findIndex(info => info.number_lesson_in_year === lessonNumber);

        if (lessonIndexInCalendar !== -1) {
            updatedCalendarInfo[lessonIndexInCalendar].theme = themes[lessonIndex].theme;
            setCalendarInfo(updatedCalendarInfo);
            setType(themes[lessonIndex].type);
        }

        await saveThemeInfo(
            currentLesson,
            lessonNumber,
            themes[lessonIndex].theme,
            type
        );
    };

    if (themes.length === 0 || !calendarInfo || !lessonNumber) {
        return <div className={styles.loader}>
            <Loader />
        </div>
    }

    return (
        <div className={styles.list__wrapper}>
            {themes.map((lesson, index) => (
                // date === lesson.date && (
                <div key={index} className={styles.content__item}>
                    <div className={styles.select__wrapper}>
                        <h3 className={styles.title}>Дата занятия: {date}</h3>
                        <select className={styles.select} name="lessonType" onChange={(e) => setType(e.target.value)} value={type}>
                            <option value="default">Не указано</option>
                            <option value="lecture">Лекция</option>
                            <option value="practice">Практика</option>
                        </select>
                    </div>
                    <div className={styles.info}>
                        {showForm === index && (
                            <motion.div
                                initial="hidden"
                                animate={showForm === index ? "visible" : "hidden"}
                                variants={formVariants}
                                className={styles.topicForm}
                            >
                                <input
                                    type="text"
                                    value={lesson.theme || ''}
                                    onChange={(e) => handleThemeChange(index, e.target.value)}
                                    placeholder='Введите название темы'
                                    className={`${styles.topicInput}`}
                                />

                            </motion.div>
                        )}
                        {!lesson.theme || isEditing[index] ? (
                            <button
                                className={styles.info__button__add}
                                onClick={() => toggleForm(index)}
                            >
                                {isEditing[index] ? 'Сохранить' : 'Добавить тему'}
                            </button>
                        ) : (
                            <button
                                className={styles.info__button__edit}
                                onClick={() => toggleForm(index)}
                            >
                                Изменить
                            </button>
                        )}
                        <div className={`${styles.info__wrapper} ${lesson.theme && styles.info__wrapper__active}`}>
                            {/* <p className={styles.info__text}>Время занятия: В РАЗРАБОТКЕ</p> */}
                            {lesson.theme && <p className={acitveTheme ? styles.info__text__theme__active : styles.info__text__theme} onClick={() => setActiveTheme(!acitveTheme)}>Тема: {lesson.theme}</p>}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}
export default KTPList;