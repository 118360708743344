import React, { useEffect, useRef, useState } from 'react';
import styles from './MiniMenu.module.css';
import { IoMdClose } from 'react-icons/io';
import { useSelector, useDispatch } from 'react-redux';
import { setPosition } from '../../reducers/miniMenuReducer'
import LessonInfo from '../LessonInfo/LessonInfo'

function MiniMenu({ students }) {
    const [active, setActive] = useState(false);
    const menuRef = useRef(null);

    const dispatch = useDispatch();
    const { x, y, visible } = useSelector(state => state.position);

    useEffect(() => {
        function handleClickOutside(event) {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                dispatch(setPosition({ visible: false }))
            }
        }

        if (visible) {
            document.addEventListener('click', handleClickOutside);
        }

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [visible]);

    return (
        <>
            {active && <LessonInfo students={students} active={active} setActive={setActive} />}
            {visible && !active && (
                <div
                    ref={menuRef}
                    style={{
                        position: 'fixed',
                        top: y,
                        left: x,
                        border: '1px solid #ccc',
                        backgroundColor: '#fff',
                        padding: '8px',
                        borderRadius: '10px',
                        zIndex: '2'
                    }}
                >
                    <div className={styles.nav__menu}>
                        <p className={styles.mini__menu}>Мини меню</p>
                        <IoMdClose
                            className={styles.modal__close}
                            onClick={() => dispatch(setPosition({ visible: false }))}
                        />
                    </div>

                    <div className={styles.menu__content}>
                        <p className={styles.info} onClick={() => {
                            setActive(true);
                            dispatch(setPosition({ visible: false }))
                        }}> Информация об уроке</p>
                    </div>
                </div >
            )
            }
        </>
    );
}

export default MiniMenu;
