import React, { useEffect, useState } from 'react';
import styles from './StatisticsTable.module.css';
import { getStatistics } from '../../http/groupApi';
import Loader from '../../components/Loader/Loader';

const thInfo = [
    'Средний процент посещаемости',
    'Посещаемость',
    'Количество занятий',
    'Средний балл',
];

function StatisticsTable({ currentGroup, currentLesson }) {

    const [students, setStudents] = useState([]);
    const [info, setInfo] = useState([]);

    useEffect(() => {
        getInfo();
    }, [currentGroup, currentLesson]);

    const getInfo = async () => {
        const data = await getStatistics(currentGroup, currentLesson);
        setStudents(Object.values(data.students));
        setInfo(data.info);
    };

    return (
        <div className={styles.groupTable__wrapper}>
            {students ? <div className={styles.tableContainer}>
                <table className={styles.groupTable}>
                    <thead>
                        <tr className={styles.tr__head}>
                            <th className={styles.th}><span className={styles.students__title}>Студенты</span></th>
                            {thInfo.map((info, index) => (
                                <th className={styles.th} key={index}><span>{info}</span></th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {students.map((student, studentIndex) => (
                            <tr className={styles.tr} key={studentIndex}>
                                <td className={styles.td}>
                                    <div className={styles.studentInfo}>
                                        <span className={styles.fio}>{studentIndex + 1}. <span className={styles.name}>{student.FIO}</span></span>
                                    </div>
                                </td>

                                {info[studentIndex] !== undefined ? (
                                    <>
                                        <td className={styles.td}><span>{info[studentIndex].attendance_rate}</span></td>
                                        <td className={styles.td}><span>{info[studentIndex].attendance_count}</span></td>
                                        <td className={styles.td}><span>{info[studentIndex].lesson_count}</span></td>
                                        <td className={styles.td}><span>{info[studentIndex].average_grade !== null ? info[studentIndex].average_grade : 'N/A'}</span></td>
                                    </>
                                ) :
                                    <>
                                        <td className={styles.td}><span></span></td>
                                        <td className={styles.td}><span></span></td>
                                        <td className={styles.td}><span></span></td>
                                        <td className={styles.td}><span></span></td>
                                    </>

                                }
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div> :
                <div className={styles.loader}>
                    <Loader />
                </div>
            }
        </div>
    );
}

export default StatisticsTable;
