export const AUTHORIZATION = '/authorization'
export const HOME = '/'
export const PROFILE = '/profile'
export const SCHEDULE = '/schedule'
export const JOURNAL = '/journal'
export const GROUPS = '/groups'
export const TEACHERS = '/teachers'
export const PERFORMANCE = '/performance'
export const STATISTICS = '/statistics'
export const LOGS = '/logs'
export const SCHEDULE_TEACHER = '/schedule-teacher'
export const KTP = '/ktp'
export const UPDATES = '/updates'
export const BIG_SCHEDULE = '/big-schedule'