import React, { useState } from 'react'
import styles from './Statistics.module.css'
import BackMenu from '../../components/BackMenu/BackMenu'
import StatisticsTable from '../../components/StatisticsTable/StatisticsTable'
import AllGroups from '../../components/AllGroups/AllGroups'
import GetKTPLesson from '../../components/GetKTPLesson/GetKTPLesson'
import { useSelector } from 'react-redux'

function Statistics() {

    const user = useSelector(state => state.user);

    const [currentLesson, setCurrentLesson] = useState(user.subjects[0]);
    const [currentGroup, setCurrentGroup] = useState(localStorage.getItem('lastSchedule') || 'ИБ - 12');

    const getNewInfo = async (group, lessonName) => {
        setCurrentGroup(group);
    }

    return (
        <section className={styles.statistics__wrapper}>

            <BackMenu />

            <div className={styles.statistics__content}>

                <div className={styles.statistics__header}>
                    <h2 className={styles.statistics__title}>Статистика ({currentGroup || localStorage.getItem('lastSchedule')})</h2>
                    <div className={styles.statistics__nav}>
                        <GetKTPLesson currentLesson={currentLesson} setCurrentLesson={setCurrentLesson} />
                        <AllGroups getNewInfo={getNewInfo} />
                    </div>
                </div>

                <div className={styles.statistics__table}>
                    <StatisticsTable currentGroup={currentGroup} currentLesson={currentLesson} />
                </div>
            </div>

        </section>
    )
}

export default Statistics