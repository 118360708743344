import React, { useEffect, useState } from 'react';
import styles from './NotFound.module.css';
import { useNavigate } from 'react-router-dom';

function NotFound() {
    const [text, setText] = useState('');
    const [active, setActive] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fullText = '404, Страница не найдена.';
        let currentIndex = 0;

        const interval = setInterval(() => {
            if (currentIndex <= fullText.length) {
                setText(fullText.slice(0, currentIndex));
                currentIndex += 1;
            } else {
                clearInterval(interval);
                setActive(true);
            }
        }, 100);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <p className={styles.text}>
                    {text}
                </p>
                <span className={active ? styles.handleActive : styles.handle}></span>
            </div>

            <button className={styles.button} onClick={() => navigate(-1)}>Назад</button>

        </div>
    );
}

export default NotFound;
