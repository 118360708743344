import { Routes, Route } from 'react-router-dom'
import { authRoutes, authMoreRoutes, adminRoutes } from '../../routes';
import NotFound from '../../pages/NotFound/NotFound';
import Auth from '../../pages/Auth/Auth';
import { useSelector } from 'react-redux';
import styles from './AppRouter.module.css';
import Loader from '../Loader/Loader';

function AppRouter({ loading }) {

    const user = useSelector(state => state.user);

    if (!user) {
        return
    }

    return (
        <Routes>
            <Route path="/authorization" element={<Auth />} />

            {user.role === 1 && authRoutes.map(({ path, Component }) => (
                <Route key={path} path={path} element={<Component />} />
            ))}

            {user.role === 2 && authMoreRoutes.map(({ path, Component }) => (
                <Route key={path} path={path} element={<Component />} />
            ))}

            {user.role === 3 && adminRoutes.map(({ path, Component }) => (
                <Route key={path} path={path} element={<Component />} />
            ))}

            <Route path="/*" element={loading ?
                (<div className={styles.loader}>
                    <Loader />
                </div>) : <NotFound />} />

        </Routes>
    )
}

export default AppRouter