import React, { useEffect, useState } from 'react';
import styles from './LessonInfo.module.css';
import { IoMdClose } from 'react-icons/io';
import { useSelector } from 'react-redux';
import { updateComments, fetchComments } from '../../http/journalApi';

function LessonInfo({ students, active, setActive }) {
    const { studentId, date, studentName } = useSelector(state => state.position);
    const [theme, setTheme] = useState([]);
    const [text, setText] = useState('');
    const [isActive, setIsActive] = useState(false);
    const [changeText, setChangeText] = useState('');
    const [prevInfo, setPrevInfo] = useState({ prevId: '', prevDate: '' });

    const profileData = useSelector(state => state.user);

    useEffect(() => {

        if (active) {
            return setIsActive(true);
        }

    }, [active]);

    useEffect(() => {

        if (active) {
            postComments();
            getInfo();
        }

        setPrevInfo({ prevId: studentId, prevDate: date });
    }, [date, studentId]);

    const postComments = async () => {
        if (changeText !== text) {
            await updateComments(
                localStorage.getItem('lastSchedule') || 'ЩИКО - 01 - 23',
                prevInfo.prevId,
                localStorage.getItem('lastLesson') || 'Иностранный язык',
                prevInfo.prevDate,
                text
            );
        }
    }

    const getInfo = async () => {
        setText('');
        setChangeText('');
        const data = await fetchComments(
            localStorage.getItem('lastSchedule') || 'ЩИКО - 01 - 23',
            studentId,
            localStorage.getItem('lastLesson') || 'Иностранный язык',
            date
        );
        setTheme(data?.theme);
        setText(data.comment);
        setChangeText(data.comment);
    }

    const handleClose = () => {
        setIsActive(false);
        setTimeout(() => {
            setActive(false);
        }, 100);
        postComments();
    }

    return (
        <>
            <div className={`${styles.container} ${isActive ? styles.active : ''}`}>
                <IoMdClose className={styles.close__button} onClick={() => {
                    handleClose();
                }} />

                <div className={styles.lesson__content}>
                    <h2 className={styles.date}>Дата: {date || 'Loading...'}</h2>

                    <div className={styles.comments}>
                        <p className={styles.theme}>Тема: {theme || 'НЕТ'}</p>
                        <p>Комментарий преподавателя</p>
                        <p>для ({studentName || 'No student name'})</p>
                        <textarea value={text} onChange={(e) => setText(e.target.value)}
                            className={styles.textArea} cols="30" rows="10"
                            placeholder='Ваш комментарий...'></textarea>
                    </div>
                </div>

                <div className={styles.general__info}>
                    <p className={styles.students}>Всего студентов: {students}</p>
                </div>
            </div>
        </>
    );
}

export default LessonInfo;
