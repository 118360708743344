const defaultState = {
    x: 0,
    y: 0,
    visible: false,
    studentName: '',
    studentId: '',
    date: ''
}

export default function userReducer(state = defaultState, action) {

    switch (action.type) {
        case 'SET_POSITION':

            const { positionX, positionY, visible } = action.payload

            return {
                ...state,
                x: positionX,
                y: positionY,
                visible: visible
            }
        case 'SET_MODAL_INFO':

            const { studentName, studentId, date } = action.payload

            return {
                ...state,
                studentName: studentName,
                studentId: studentId,
                date: date
            }

        default:
            return state
    }
}

export const setPosition = info => ({ type: 'SET_POSITION', payload: info });
export const setLessonInfo = info => ({ type: 'SET_MODAL_INFO', payload: info });