import { $host, $authHost } from "./index";

export const getJournalUsers = async (group, subject_name, currentMonth) => {
    try {
        const { data } = await $authHost.get('kpk/get_month_schedule_for_item', {
            params: {
                date: currentMonth,
                group: group,
                subject_name: subject_name
            }
        });
        return data;
    } catch (error) {
        console.error("Failed to fetch AllGroups:", error);
    }
}

export const fetchJournalEstimation = async (group, subject, date, grades, studentId, numberLesson) => {
    try {
        const { data } = await $authHost.post('kpk/update_journal_entry', {
            group: group,
            subject: subject,
            date: date,
            grades: grades,
            studentId: studentId,
            numberLesson: numberLesson
        });
        return data;
    } catch (error) {
        console.error("Failed to fetch Estimation:", error);
    }
}

export const fetchComments = async (group, studentId, lessonName, date) => {

    try {
        const { data } = await $authHost.post('kpk/journay/get_lesson_info', {
            group: group,
            date: date,
            lessonName: lessonName,
            studentId: studentId
        });
        return data;
    } catch (error) {
        console.error("Failed to fetch Comments:", error);
    }
}

export const updateComments = async (group, studentId, lessonName, date, text) => {
    try {
        const { data } = await $authHost.post('kpk/journay/update_comment', {
            group: group,
            date: date,
            lessonName: lessonName,
            studentId: studentId,
            text: text
        });
        return data;
    } catch (error) {
        console.error("Failed to fetch updateComments:", error);
    }
}

export const fetchAllJournal = async (page, limit, searchGroup, searchTeacher, searchAuditory) => {
    try {
        const { data } = await $authHost.get('kpk/journay/all_schedule', {
            params: {
                page: page,
                limit: limit,
                search_group: searchGroup,
                search_teacher: searchTeacher,
                search_auditory: searchAuditory
            }
        });
        return data;
    } catch (error) {
        console.error("Failed to fetch fetchAllJournal:", error);
    }
}