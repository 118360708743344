import React, { useEffect, useState } from 'react';
import styles from './EditMode.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { IoMdClose } from "react-icons/io";
import { setEditedData } from '../../reducers/scheduleReducer';

function EditMode({ openEditMode, setOpenEditMode, handleSave }) {
    const dispatch = useDispatch();
    const { groupSubject, selectedLesson, editedData } = useSelector(state => state.schedule);
    const [updatedLesson, setUpdatedLesson] = useState({ ...selectedLesson.value });

    useEffect(() => {
        setUpdatedLesson({ ...selectedLesson.value });
    }, [selectedLesson]);

    useEffect(() => {
        if (openEditMode) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [openEditMode]);

    const handleLessonChange = (lessonName) => {
        setUpdatedLesson(prev => ({ ...prev, name: lessonName }));
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        const inputValue = type === 'checkbox' ? checked : value;

        setUpdatedLesson(prev => ({ ...prev, [name]: inputValue }));
    };

    const handleSaveSubject = () => {
        updateSchedule(updatedLesson);
    };

    const handleRemoveLesson = () => {
        updateSchedule({ name: "", room: "", teacher: "", potok: false });
    };

    const updateSchedule = (lessonData) => {
        const updatedWeekSchedule = { ...editedData[selectedLesson.week] };
        const updatedDaySchedule = [...updatedWeekSchedule[selectedLesson.day]];
        updatedDaySchedule[selectedLesson.index] = lessonData;
        updatedWeekSchedule[selectedLesson.day] = updatedDaySchedule;

        const updatedFullSchedule = {
            ...editedData,
            [selectedLesson.week]: updatedWeekSchedule
        };

        dispatch(setEditedData({ updateData: updatedFullSchedule }));
        if (handleSave) handleSave(updatedFullSchedule);
        setOpenEditMode(false);
    };

    return (
        <div className={styles.overlay}>
            <div className={styles.edit__wrapper}>
                <IoMdClose className={styles.close__btn} onClick={() => setOpenEditMode(false)} />
                <div className={styles.edit__container}>
                    <h2 className={styles.edit__title}>Режим редактирования</h2>
                    <div className={styles.input__container}>
                        <div className={styles.input__wrapper}>
                            <label className={styles.input__label}>Предмет:</label>
                            <input value={updatedLesson.name} className={styles.input} type="text" disabled />
                        </div>
                        <div className={styles.input__wrapper}>
                            <label className={styles.input__label}>Кабинет:</label>
                            <input name="room" value={updatedLesson.room} onChange={handleInputChange} className={styles.input} type="text" />
                        </div>
                        <div className={styles.input__wrapper}>
                            <label className={styles.input__label}>Преподаватель:</label>
                            <input name="teacher" value={updatedLesson.teacher} onChange={handleInputChange} className={styles.input} type="text" />
                        </div>
                        <div className={styles.input__wrapper__checkbox}>
                            <label className={styles.input__label}>Потоковое занятие:</label>
                            <input name="potok" checked={updatedLesson.potok} onChange={handleInputChange} className={styles.checkbox} type="checkbox" />
                        </div>
                    </div>
                    <div className={styles.edit__btn__wrapper}>
                        <button className={styles.edit__btn__save} onClick={handleSaveSubject}>Сохранить</button>
                    </div>
                </div>
                <div className={styles.edit__title__wrapper}>
                    <ul className={styles.select}>
                        {groupSubject.map((data, index) => (
                            <li className={data.name === updatedLesson.name ? styles.select__item__active : styles.select__item}
                                key={data.name}
                                onClick={() => handleLessonChange(data.name)}
                            >{data.name}</li>
                        ))}
                    </ul>
                    <div className={styles.select__item__remove} onClick={handleRemoveLesson}>Убрать пару</div>
                </div>
            </div>
        </div>
    );
}

export default EditMode;
