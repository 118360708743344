import React, { useState } from 'react';
import styles from './Auth.module.css';
import { useNavigate } from 'react-router-dom';
import { AiOutlineUser } from 'react-icons/ai';
import { FiLock } from 'react-icons/fi';
import { fetchUser, fetchProfileToStart } from '../../http/userApi';
import { useDispatch } from 'react-redux';
import { setId, setUser } from '../../reducers/userReducer'
import { useSnackbar } from 'notistack';

function Auth() {
    const [login, setLogin] = useState(localStorage.getItem('remember') || '');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [loading, setLoading] = useState(false);

    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLoginChange = (e) => {
        setLogin(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleRememberMeChange = () => {
        setRememberMe(!rememberMe);
    };

    const handleRememberMeClick = () => {
        setRememberMe(!rememberMe);
    };

    const handleLogin = async () => {
        try {
            setLoading(true);
            if (typeof rememberMe !== 'boolean') {
                throw new Error('Invalid rememberMe value');
            }

            rememberMe ? localStorage.setItem('remember', login) : localStorage.removeItem('remember');

            const data = await fetchUser(login, password, rememberMe);

            if (!data) {
                throw new Error('Token not received');
            }

            localStorage.setItem('token', data.token);
            setLoading(false);
            dispatch(setId(data.user_id));
            getInfo();
            navigate(`/profile`);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                enqueueSnackbar('Неверный логин или пароль', { variant: 'error' });
            } else {
                enqueueSnackbar('Что-то пошло не так. Пожалуйста, попробуйте еще раз.', { variant: 'error' });
            }
            setLoading(false);
        }
    };

    const getInfo = async () => {
        try {
            const data = await fetchProfileToStart();
            dispatch(setUser(data));
        } catch (e) {
            navigate('/authorization');
        }
    }

    return (
        <div className={styles.container}>
            <form className={styles.form__auth}>

                <div className={styles.form__input__wrapper}>

                    <div className={styles.content}>

                        {loading && <div className={styles.loader}></div>}

                        <h1 className={styles.title__login}>Авторизация</h1>

                        <div className={styles.input__wrapper}>
                            <AiOutlineUser className={styles.icon} />
                            <input
                                className={styles.input}
                                placeholder="Логин"
                                value={login}
                                onChange={handleLoginChange}

                            />
                        </div>

                        <div className={styles.input__wrapper}>
                            <FiLock className={styles.icon} />
                            <input
                                className={styles.input}
                                placeholder="Пароль"
                                type="password"
                                value={password}
                                onChange={handlePasswordChange}
                            />
                        </div>

                        <div className={styles.remember}>
                            <div>
                                <input
                                    className={styles.checkbox}
                                    type="checkbox"
                                    checked={rememberMe}
                                    onChange={handleRememberMeChange}
                                />
                                <label
                                    className={styles.remember__text}
                                    htmlFor="rememberMeCheckbox"
                                    onClick={handleRememberMeClick}
                                >
                                    Запомнить
                                </label>
                            </div>
                            <div>
                                <a className={styles.link}
                                >Забыли пароль?</a>
                            </div>
                        </div>

                        <button type="button" className={styles.button} onClick={handleLogin}>Войти</button>
                    </div>
                </div>

                <div className={styles.images__wrapper}>
                    <img className={styles.logo__mirea} src="https://www.tadviser.ru/images/f/f4/MIREA_Gerb_2021.png" alt="" />
                    <div className={styles.text__wrapper}>
                        <h2 className={styles.title}>Здравствуйте!</h2>
                        <p className={styles.text}>Авторизация преподавателей и администрации для доступа к инструментам и ресурсам колледжа.</p>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default Auth;
