import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchProfileToStart } from './http/userApi'
import { fetchAllGroups } from './http/scheduleApi';
import { setUser } from './reducers/userReducer'
import { setGroup } from './reducers/groupReducer'
import AppRouter from './components/AppRouter/AppRouter';
import './styles/App.css';

function App() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    getInfo();
    getGroup();
  }, []);

  const getGroup = async () => {
    try {
      const data = await fetchAllGroups();
      dispatch(setGroup(data));
    } catch (e) {
      navigate('/authorization');
    }
  }

  const getInfo = async () => {
    setLoading(true);
    try {
      const data = await fetchProfileToStart();
      data.message === 'Неверный формат токена пользователя' ? navigate('/authorization') :
        dispatch(setUser(data));
    } catch (e) {
      navigate('/authorization');
    }
    setLoading(false);
  }
  return (
    <div className="App">
      <AppRouter loading={loading} />
    </div>
  );
}

export default App;
