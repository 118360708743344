import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import styles from './GetKTPLesson.module.css';

function GetKTPLesson({ currentLesson, setCurrentLesson }) {
    const [openModal, setOpenModal] = useState(false);
    const user = useSelector(state => state.user);
    const modalRef = useRef(null);

    function handleClickOutside(event) {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            setOpenModal(false);
        }
    }

    function handleSelectLesson(lesson) {
        setCurrentLesson(lesson);
        setOpenModal(false);
    }

    useEffect(() => {
        if (openModal) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [openModal]);

    return (
        <div className={styles.container} ref={modalRef}>
            <button className={styles.button} onClick={() => setOpenModal(!openModal)}>
                {currentLesson || user.subjects[0]}
            </button>
            {openModal && (
                <ul className={styles.list}>
                    {user.subjects.map((lesson) => (
                        <li key={lesson} className={styles.item} onClick={() => handleSelectLesson(lesson)}>
                            {lesson}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}

export default GetKTPLesson;
