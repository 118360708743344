

import React from 'react';
import styles from './ProfileInfo.module.css';

const ProfileInfo = ({ label, value, type = "text", isEditing, onChange, defaultValue }) => {

    const getInputType = () => {
        if (type === "password" && isEditing) {
            return "text";
        }
        return type;
    };

    const getDisplayValue = () => {
        if (type === "password" && !isEditing) {
            return "********";
        }
        return value;
    };

    return (
        <div className={styles.name__wrapper}>
            <h3 className={styles.profile__label}>{label}</h3>
            {isEditing ? (
                <input
                    type={getInputType()}
                    className={styles.profileInput}
                    defaultValue={defaultValue}
                    onChange={(e) => onChange(e.target.value)}
                />
            ) : (
                <p className={styles.profileName}>{getDisplayValue()}</p>
            )}
        </div>
    );
};

export default ProfileInfo;
