import React, { useEffect, useRef, useState } from 'react';
import styles from './CalendarMenu.module.css';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import MonthTitle from '../MonthTitle/MonthTitle';

function MonthDropdown({ currentMonth, onSelectMonth }) {
    const months = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"];

    const monthIndex = currentMonth.getMonth();

    return (
        <ul className={styles.monthDropdown}>
            {months.map((month, index) => (
                <li key={index} className={monthIndex === index ? styles.current__month : ""} onClick={() => onSelectMonth(index)}>
                    {month}
                </li>
            ))}
        </ul>
    );
}

function Calendar({ month, setMonth }) {

    const [currentMonth, setCurrentMonth] = useState(month || new Date());
    const [currentMonthKey, setCurrentMonthKey] = useState(0);
    const [animateDirection, setAnimateDirection] = useState("forward");
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef();

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    const handleNextMonth = () => {
        setAnimateDirection("backward");
        setCurrentMonth((prevMonth) => {
            setMonth(new Date(prevMonth.getFullYear(), prevMonth.getMonth() + 1, 1));
            const nextMonth = new Date(prevMonth.getFullYear(), prevMonth.getMonth() + 1, 1);
            return nextMonth;
        });
        setCurrentMonthKey((prevKey) => prevKey + 1);
    };

    const handlePrevMonth = () => {
        setAnimateDirection("forward");
        setCurrentMonth((prevMonth) => {
            setMonth(new Date(prevMonth.getFullYear(), prevMonth.getMonth() - 1, 1));
            const prevMonthDate = new Date(prevMonth.getFullYear(), prevMonth.getMonth() - 1, 1);
            return prevMonthDate;
        });
        setCurrentMonthKey((prevKey) => prevKey + 1);
    };

    const handleMonthClick = () => {
        setShowDropdown(!showDropdown);
    };

    const handleSelectMonth = (monthIndex) => {
        setMonth(new Date(currentMonth.getFullYear(), monthIndex, 1));
        setCurrentMonth(new Date(currentMonth.getFullYear(), monthIndex, 1));
        setShowDropdown(false);
    };

    return (
        <div className={styles.calendar}>
            <IoIosArrowBack className={styles.navButton} onClick={handlePrevMonth} />
            <div onClick={handleMonthClick} ref={dropdownRef}>
                <MonthTitle currentMonthKey={currentMonthKey} currentMonth={currentMonth} setMonth={setMonth} animateDirection={animateDirection} />
                {showDropdown && <MonthDropdown currentMonth={currentMonth} onSelectMonth={handleSelectMonth} />}
            </div>
            <IoIosArrowForward className={styles.navButton} onClick={handleNextMonth} />
        </div>
    );
}

export default Calendar;
