import React, { useEffect, useState } from 'react';
import styles from './KTPAddon.module.css';
import KTPList from '../KTPList/KTPList';
import { fetchCalendarInfo } from '../../http/KTPApi';
import { motion } from 'framer-motion';


function KTPAddon({ lessonName, currentMonth }) {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [lessonInfo, setLessonInfo] = useState([]);
    const [lessonDate, setLessonDate] = useState();
    const [lessonNumber, setLessonNumber] = useState();

    const daysOfWeek = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];

    useEffect(() => {
        getLessonInfo(localStorage.getItem('lastSchedule') || 'ИБ - 12',
            lessonName || 'Иностранный язык');
    }, [lessonName, currentMonth.toLocaleString('default', { month: 'long', year: 'numeric' })]);

    const getLessonInfo = async (group, subject) => {
        const data = await fetchCalendarInfo(group, subject);

        const formattedData = data.map(item => ({
            ...item,
            date: new Date(item.date.split('.').reverse().join('-'))
        }));
        setLessonInfo(formattedData);
    };

    const getDaysInMonth = (year, month) => {
        return new Date(year, month + 1, 0).getDate();
    };

    const getFirstDayOfMonth = (year, month) => {
        return new Date(year, month, 1).getDay();
    };

    const generateCalendarDays = () => {
        const days = [];
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth();
        const daysInMonth = getDaysInMonth(year, month);
        const firstDay = getFirstDayOfMonth(year, month) || 7;

        for (let i = 1; i < firstDay; i++) {
            days.push(<div className={styles.empty} key={`empty-${i}`}></div>);
        }

        for (let i = 1; i <= daysInMonth; i++) {
            const currentDay = new Date(year, month, i);
            const lesson = lessonInfo.find(info => info.date.toDateString() === currentDay.toDateString());

            let animateBackground;
            if (lesson && lesson.theme) {
                animateBackground = "rgb(194, 219, 175)";
            } else if (lesson && !lesson.theme) {
                animateBackground = "rgb(255 174 88)";
            }

            if (lesson) {
                days.push(
                    <motion.div
                        className={`${styles.day} ${lesson.theme ? styles.green : styles.orange}`}
                        key={i}
                        initial={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
                        animate={{ backgroundColor: animateBackground }}
                        transition={{ duration: 0.5 }}
                        onClick={() => getThemeInfo(lesson)}
                    >
                        {i}
                    </motion.div>
                );
            } else {
                days.push(
                    <div className={styles.day} key={i}>
                        {i}
                    </div>
                );
            }
        }

        return days;
    };

    function formatDateShort(dateString) {
        const date = new Date(dateString);
        return `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear().toString().slice(-2)}`;
    }

    const getThemeInfo = async (lesson) => {
        setLessonDate(formatDateShort(lesson?.date));
        setLessonNumber(lesson?.number_lesson_in_year);
    }

    return (
        <section className={styles.container}>
            <div className={styles.calendar}>
                <div className={styles.month}>
                    <div className={styles.monthYear}>
                        {currentMonth.toLocaleString('default', { month: 'long', year: 'numeric' })}
                    </div>
                    <div className={styles.header}>
                        {daysOfWeek.map(day => (
                            <span key={day}>{day}</span>
                        ))}
                    </div>
                    <div className={styles.days}>
                        {generateCalendarDays()}
                    </div>
                </div>
            </div>

            <div className={styles.KTPList}>
                <KTPList
                    lessonNumber={lessonNumber}
                    calendarInfo={lessonInfo}
                    setCalendarInfo={setLessonInfo}
                    date={lessonDate}
                    currentLesson={localStorage.getItem('lastLesson') || 'Иностранный язык'} />
            </div>
        </section>
    );
}

export default KTPAddon;
