import React, { useState } from 'react';
import styles from './Profile.module.css';
import BackMenu from '../../components/BackMenu/BackMenu';
import setting from '../../assets/svg/setting.svg';
import { AiFillSave } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { updateProfile, fetchProfileToStart } from '../../http/userApi'
import ProfileInfo from '../../components/ProfileInfo/ProfileInfo';
import { useNavigate } from 'react-router-dom'
import { setUser } from '../../reducers/userReducer'
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';

function Profile() {

    const [editedProfile, setEditedProfile] = useState({});
    const [isEditing, setIsEditing] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { enqueueSnackbar } = useSnackbar();
    const profileData = useSelector(state => state.user);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSaveClick = async () => {
        setIsEditing(false);
        await updateProfile(editedProfile);
        getInfo();
        enqueueSnackbar('Данные успешно обновлены', { variant: 'success' });
    };

    const getInfo = async () => {
        try {
            const data = await fetchProfileToStart();
            dispatch(setUser(data));
        } catch (e) {
            navigate('/authorization');
        }
    }

    const handleChange = (field, value) => {
        setEditedProfile(prevState => ({
            ...prevState,
            [field]: value,
        }));
    };

    const logout = () => {
        localStorage.removeItem('token');
        navigate('/authorization');
    }

    return (
        <main className={styles.container}>
            <BackMenu />
            <section className={styles.profileContainer}>
                <header className={styles.profile__info}>
                    <h2 className={styles.profile__name__title}>{`
                    ${editedProfile.lastName || profileData.lastName} 
                    ${editedProfile.firstName || profileData.firstName} 
                    ${editedProfile.middleName || profileData.middleName}`}</h2>
                    <p className={styles.profileBio}>Общие сведения</p>
                </header>

                <article className={styles.profile__wrapper}>
                    <section className={styles.profile__edit__wrapper}>
                        <header className={styles.edit__info}>
                            <p className={styles.editProfile}>Информация о профиле</p>
                        </header>

                        <div className={styles.profileInfo}>
                            <ProfileInfo
                                label="Фамилия"
                                value={editedProfile.lastName || profileData.lastName}
                                isEditing={isEditing}
                                onChange={(value) => handleChange('lastName', value)}
                                defaultValue={profileData.lastName}
                            />

                            <ProfileInfo
                                label="Имя"
                                value={editedProfile.firstName || profileData.firstName}
                                isEditing={isEditing}
                                onChange={(value) => handleChange('firstName', value)}
                                defaultValue={profileData.firstName}
                            />

                            <ProfileInfo
                                label="Отчество"
                                value={editedProfile.middleName || profileData.middleName}
                                isEditing={isEditing}
                                onChange={(value) => handleChange('middleName', value)}
                                defaultValue={profileData.middleName}
                            />

                        </div>
                    </section>

                    <section className={styles.profile__edit__wrapper}>
                        {isEditing ? (
                            <div className={styles.edit} onClick={handleSaveClick}>
                                <p className={styles.editProfile}>Сохранить</p>
                                <AiFillSave />
                            </div>
                        ) : (
                            <div className={styles.edit} onClick={handleEditClick}>
                                <p className={styles.editProfile}>Редактировать</p>
                                <img src={setting} alt="" />
                            </div>
                        )}

                        <div className={styles.profile__info__container}>
                            <div className={styles.profile__info__details}>

                                <ProfileInfo
                                    label="Адрес электронной почты"
                                    value={editedProfile.email || profileData.email}
                                    isEditing={isEditing}
                                    onChange={(value) => handleChange('email', value)}
                                    defaultValue={profileData.email}
                                />

                            </div>

                            <div className={styles.profile__info__details}>

                                <ProfileInfo
                                    label="Группа"
                                    value={editedProfile.group || profileData.group || 'Нет группы'}
                                    isEditing={isEditing}
                                    onChange={(value) => handleChange('group', value)}
                                    defaultValue={profileData.group}
                                />

                            </div>
                        </div>
                    </section>
                </article>

                <footer className={styles.logout__section}>
                    <button className={styles.logout} onClick={() => logout()}>Выйти</button>
                </footer>
            </section>
        </main>
    );
}

export default Profile;

