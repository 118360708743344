import Profile from "./pages/Profile/Profile"
import SceduleTeacher from "./pages/ScheduleTeacher/ScheduleTeacher"
import Schedule from "./pages/Schedule/Schedule"
import Journal from "./pages/Journal/Journal"
import Groups from "./pages/Groups/Groups"
import Teachers from "./pages/Teachers/Teachers"
import NotFound from "./pages/NotFound/NotFound"
import KTPPage from "./pages/KTP/KTP"


import {
    PROFILE,
    SCHEDULE,
    JOURNAL,
    GROUPS,
    TEACHERS,
    PERFORMANCE,
    STATISTICS,
    LOGS,
    SCHEDULE_TEACHER,
    HOME,
    KTP,
    UPDATES,
    BIG_SCHEDULE
} from "./utils/consts"
import Updates from "./pages/Updates/Updates"
import Statistics from "./pages/Statistics/Statistics"
import BigSchedule from "./pages/BigSchedule/BigSchedule"

export const authRoutes = [
    {
        path: UPDATES,
        Component: Updates
    },
    {
        path: HOME,
        Component: Profile
    },
    {
        path: JOURNAL,
        Component: Journal
    },
    {
        path: PROFILE,
        Component: Profile
    },
    {
        path: SCHEDULE_TEACHER,
        Component: SceduleTeacher
    },
    {
        path: KTP,
        Component: KTPPage
    }
]

export const authMoreRoutes = [
    {
        path: UPDATES,
        Component: Updates
    },
    {
        path: HOME,
        Component: Profile
    },
    {
        path: JOURNAL,
        Component: Journal
    },
    {
        path: PROFILE,
        Component: Profile
    },
    {
        path: SCHEDULE_TEACHER,
        Component: SceduleTeacher
    },
    {
        path: SCHEDULE,
        Component: Schedule
    },
    {
        path: BIG_SCHEDULE,
        Component: BigSchedule
    },
    {
        path: KTP,
        Component: KTPPage
    }
]

export const adminRoutes = [
    {
        path: UPDATES,
        Component: Updates
    },
    {
        path: HOME,
        Component: Profile
    },
    {
        path: PROFILE,
        Component: Profile
    },
    {
        path: SCHEDULE,
        Component: Schedule
    },
    {
        path: JOURNAL,
        Component: Journal
    },
    {
        path: GROUPS,
        Component: Groups
    },
    {
        path: TEACHERS,
        Component: Teachers
    },
    {
        path: PERFORMANCE,
        Component: NotFound
    },
    {
        path: STATISTICS,
        Component: Statistics
    },
    {
        path: LOGS,
        Component: NotFound
    },
    {
        path: KTP,
        Component: KTPPage
    },
    {
        path: BIG_SCHEDULE,
        Component: BigSchedule
    }
]