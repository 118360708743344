function generateDatesForMonth(datesOfTheSubject, setDates) {
    const dates = [];

    for (let i = 0; i < datesOfTheSubject.length; i++) {
        dates.push({
            number: datesOfTheSubject[i].number,
            number_lesson: datesOfTheSubject[i].number_lesson
        });
    }

    const result = [];
    let prevNumber = 0;

    for (const dateObj of dates) {
        const { number, number_lesson } = dateObj;

        if (prevNumber === number) {
            result[result.length - 1].number = result[result.length - 1].number + ` (${result[result.length - 1].number_lesson})`;
            result.push({ number: number + ` (${number_lesson})`, number_lesson: number_lesson });
            prevNumber = number;
        } else {
            prevNumber = number;
            result.push({ number, number_lesson });
        }
    }

    setDates(result);
}

export default generateDatesForMonth;
