import React, { useEffect, useState, useRef } from 'react';
import styles from './SelectLesson.module.css';
import { fetchLessons } from '../../http/lessonsApi';
import { IoIosArrowDown } from 'react-icons/io';
import Calendar from '../CalendarMenu/CalendarMenu';
import { useSelector } from 'react-redux';

function ModalLesson({ setLessonName, setListOpen, listOpen, lessonName, group, currentMonth, setCurrentMonth, openKTP }) {
  const [lessons, setLessons] = useState();
  const [lessonsLoaded, setLessonsLoaded] = useState(false);
  const lessonRef = useRef(null);

  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (typeof group === 'undefined') return

    getLessons(localStorage.getItem('lastSchedule') || 'ЩАКО - 01 - 22', user.email);
    localStorage.setItem('lastLesson', 'Выберите предмет');
  }, [group]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const getLessons = async (group) => {
    try {
      setLessonsLoaded(false);
      const data = await fetchLessons(group, user.email);
      setLessons(data.subjects);
      setLessonsLoaded(true);
    } catch (error) {
      console.error('Error fetching lessons:', error);
    }
  };

  const handleChangeLesson = (name) => {
    setLessonName(name);
    setListOpen(false);
    localStorage.setItem('lastLesson', name);
  };

  const handleClickOutside = (event) => {
    if (
      lessonRef.current &&
      !lessonRef.current.contains(event.target) &&
      !event.target.classList.contains(styles.icon) &&
      !event.target.classList.contains(styles.journal__subtitle)
    ) {
      setListOpen(false);
    }
  };

  return (
    <>
      <div className={styles.section__wrapper}>
        <Calendar month={currentMonth} setMonth={setCurrentMonth} />
        <div className={styles.journal__subtitle__wrapper}>
          <h3 className={`${styles.journal__subtitle} ${openKTP && styles.journal__subtitle__open__ktp}`} onClick={() => setListOpen(!listOpen)}>
            {localStorage.getItem('lastLesson')}
          </h3>
          <IoIosArrowDown className={styles.icon} onClick={() => setListOpen(!listOpen)} />
        </div>
      </div>

      {listOpen && (
        <ul className={styles.select__container} ref={lessonRef}>
          {lessons &&
            lessons.map((lesson, index) => (
              lesson && (
                <li className={styles.li} key={index} onClick={() => handleChangeLesson(lesson)} value={lesson}>
                  {lesson}
                </li>
              )
            ))}
        </ul>
      )}
    </>
  );
}

export default ModalLesson;
