import { $host, $authHost } from "./index";

export const getGroup = async (group) => {
    try {
        const { data } = await $authHost.get(`kpk/get_students/${group}`);
        return data;
    } catch (error) {
        console.error("Failed to fetch Groups:", error);
    }
}

export const getStatistics = async (group, subject) => {
    try {
        const { data } = await $authHost.get(`kpk/statictics`, {
            params: {
                group: group,
                subject: subject
            }
        });
        return data;
    } catch (error) {
        console.error("Failed to fetch Statistics:", error);
    }
}

export const getTeachers = async () => {
    try {
        const { data } = await $authHost.get(`kpk/teachers_all`);
        return data;
    } catch (error) {
        console.error("Failed to fetch Teachers:", error);
    }
}