import React, { useState, useRef, useEffect } from 'react';
import { IoCloseOutline } from "react-icons/io5";
import styles from './BotButtons.module.css';
import { fetchSchedule, sendScheduleBot } from '../../http/scheduleApi';
import { setSchedule } from '../../reducers/scheduleReducer';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';

function BotButtons() {
    const [file, setFile] = useState(null);
    const [file2Weeks, setFile2Weeks] = useState(null);
    const [isEmpty, setIsEmpty] = useState(false);
    const fileInputRef1 = useRef(null);
    const fileInputRef2 = useRef(null);

    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    useEffect(() => {
        if (file || file2Weeks) {
            setIsEmpty(true);
        } else {
            setIsEmpty(false);
        }
    }, [file, file2Weeks]);

    const handleFileChange = (event, setter) => {
        const selectedFile = event.target.files[0];
        setter(selectedFile);
    };

    const sendSchedule = async () => {

        enqueueSnackbar('Отправление расписания...', { variant: 'info' });

        const formData = new FormData();

        if (file) {
            formData.append('file', file);
            formData.append('flag_schedule', false);
        }

        if (file2Weeks) {
            formData.append('file', file2Weeks);
            formData.append('flag_schedule', true);
        }

        if (!file && !file2Weeks) {
            formData.append('flag_schedule', null);
            formData.append('flag_telegram', true);
        }

        const data = await sendScheduleBot(formData);

        if (data) {
            enqueueSnackbar('Расписание успешно отправлено!', { variant: 'success' });
            const data = await fetchSchedule(localStorage.getItem('lastSchedule') ? localStorage.getItem('lastSchedule') : 'ИБ - 12');
            dispatch(setSchedule({ groupSubject: data.subjects, editedData: data.scheduleData, dates: data.date }));
            return;
        }

        enqueueSnackbar('Произошла ошибка при отправке расписания!', { variant: 'error' });
    };

    return (
        <div className={styles.button__container}>
            <button className={styles.button__upload} onClick={sendSchedule}>
                Отправить расписание
            </button>

            <hr className={styles.hr} />

            <div className={styles.custom__file__input}>
                <input
                    type="file"
                    ref={fileInputRef1}
                    className={styles.hidden__input}
                    onChange={(e) => handleFileChange(e, setFile)}
                    accept=".xlsx"
                    disabled={isEmpty}
                />
                <div className={styles.custom__button} onClick={() => file2Weeks === null || file2Weeks === undefined ? fileInputRef1.current.click() : null}>
                    <p className={styles.custom__text}>{file ? file.name : 'На один день'}</p>
                    {file && <IoCloseOutline className={styles.close__icon} onClick={() => setFile(null)} />}
                </div>

            </div>
            <div className={styles.custom__file__input}>
                <input
                    type="file"
                    ref={fileInputRef2}
                    className={styles.hidden__input}
                    onChange={(e) => handleFileChange(e, setFile2Weeks)}
                    accept=".xlsx"
                    disabled={isEmpty}
                />
                <div className={styles.custom__button} onClick={() => file === null || file === undefined ? fileInputRef2.current.click() : null}>
                    <p className={styles.custom__text}>{file2Weeks ? file2Weeks.name : 'На неделю'}</p>
                    {file2Weeks && <IoCloseOutline className={styles.close__icon} onClick={() => setFile2Weeks(null)} />}
                </div>
            </div>
        </div>
    );
}

export default BotButtons;
