import React, { useState } from 'react';
import styles from './QuickCast.module.css';

function QuickCast({ setSelectedIndex, setOpenKTP, openKTP }) {
    const [active, setActive] = useState('Выкл');

    const handleKeyClick = (value, index) => {
        setSelectedIndex(value);
        setActive(`${index}`);
    };

    return (
        <div className={styles.container}>
            <div className={styles.keys}>

                <button
                    className={`${styles.selectedIndex} ${active === "Выкл" ? styles.selected : ''}`}
                    onClick={() => handleKeyClick(null, 'Выкл')}
                >
                    Выкл
                </button>
                <button
                    className={`${styles.selectedIndex} ${active === 'Н' ? styles.selected : ''}`}
                    onClick={() => handleKeyClick('Н', 'Н')}
                >
                    Н
                </button>

                {[2, 3, 4, 5].map((number) => (
                    <button
                        key={number}
                        className={`${styles.selectedIndex} ${active === `${number}` ? styles.selected : ''}`}
                        onClick={() => handleKeyClick(`${number}`, `${number}`)}
                    >
                        {number}
                    </button>

                ))}

                <hr className={styles.hr} />

                <button
                    className={`${styles.KTP} ${openKTP ? styles.selected__KTP : ''}`}
                    onClick={() => setOpenKTP(!openKTP)}
                >
                    КТП
                </button >
            </div>
        </div>
    );
}

export default QuickCast;
