export const updateEstimation = (estimation, students, dates, selectedIndex, setEstimation, studentIndex, dateIndex, value, isClickEvent) => {

    const updatedEstimation = [...estimation];
    const studentId = students[studentIndex]._id;

    const foundEstimationIndex = updatedEstimation.findIndex(
        item =>
            item.date === dates[dateIndex].number &&
            item.studentId === studentId
    );

    if (foundEstimationIndex !== -1) {
        updatedEstimation[foundEstimationIndex] = {
            ...updatedEstimation[foundEstimationIndex],
            grade: isClickEvent ? selectedIndex : value
        };
    } else {
        updatedEstimation.push({
            date: dates[dateIndex].number,
            studentId,
            grade: isClickEvent ? selectedIndex : value
        });
    }

    setEstimation(updatedEstimation);
};
