import React, { useState, useEffect } from 'react';
import styles from './Calendar.module.css';
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { fetchCalendarInfo } from '../../http/KTPApi';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';

const Calendar = ({ setDate, setLessonNumber, calendarInfo,
  setCalendarInfo, currentLesson, currentGroup }) => {

  const weekArray = ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"];
  const monthArray = [
    "Январь", "Февраль", "Март", "Апрель", "Май", "Июнь",
    "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"
  ];

  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDay, setSelectedDay] = useState(null);
  const [currentYear, setCurrentYear] = useState(currentDate.getFullYear());
  const [currentMonth, setCurrentMonth] = useState(currentDate.getMonth());
  const [calendarDays, setCalendarDays] = useState([]);

  const user = useSelector(state => state.user);

  useEffect(() => {
    setDate(new Date().toLocaleDateString('ru-RU'));
    getInfo();
  }, [currentGroup, currentLesson]);

  useEffect(() => {
    generateCalendarDays(currentYear, currentMonth);
  }, [currentYear, currentMonth, selectedDay, calendarInfo, currentLesson]);

  useEffect(() => {
    if (calendarInfo && calendarInfo.length > 0) {
      setInitialLessonNumber();
    }
  }, [calendarInfo]);


  if (!calendarInfo) {
    return null
  }

  const setInitialLessonNumber = () => {
    const today = new Date();
    const formattedToday = `${today.getDate().toString().padStart(2, '0')}.${(today.getMonth() + 1).toString().padStart(2, '0')}.${today.getFullYear()}`;
    const lessonInfo = calendarInfo.find(info => info.date === formattedToday);

    if (selectedDay === null && lessonInfo) {
      setLessonNumber(lessonInfo.number_lesson_in_year);
    }
  };

  const generateCalendarDays = (year, month) => {
    const totalDaysInMonth = new Date(year, month + 1, 0).getDate();
    const firstDayOfWeek = new Date(year, month, 1).getDay();
    const today = new Date();

    const infoDates = calendarInfo.reduce((acc, info) => {
      const [day, month, year] = info.date.split('.').map(Number);
      acc[`${year}-${month}-${day}`] = info;
      return acc;
    }, {});

    const days = [];
    for (let i = 0; i < (firstDayOfWeek === 0 ? 6 : firstDayOfWeek - 1); i++) {
      days.push(<div key={`empty_${i}`} className={styles.calendar__day_empty}></div>);
    }

    for (let day = 1; day <= totalDaysInMonth; day++) {
      const dateKey = `${year}-${month + 1}-${day}`;
      const isToday = day === today.getDate() && month === today.getMonth() && year === today.getFullYear();
      const isSelectedDay = day === selectedDay;
      let dayClass = styles.calendar__day;

      if (isToday && !selectedDay) dayClass += ` ${styles.calendar__day__today}`;
      if (isSelectedDay) dayClass += ` ${styles.calendar__day__active}`;

      days.push(
        <div
          key={day}
          className={dayClass}
          onClick={() => handleDayClick(day, infoDates[dateKey]?.number_lesson_in_year)}
        >
          {infoDates[dateKey] ?
            <motion.div
              className={infoDates[dateKey].theme === null ? styles.not__active : styles.active}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3 }}
            ></motion.div> : null}
          {day}
        </div>
      );
    }

    setCalendarDays(days);
  };


  const getInfo = async () => {
    const data = await fetchCalendarInfo(
      localStorage.getItem('lastSchedule') || 'ИБ - 12',
      currentLesson || user.subjects[0]
    );
    setCalendarInfo(data);
  }

  const handleDayClick = (day, date) => {
    setSelectedDay(day);
    const formattedDate = `${day.toString().padStart(2, '0')}.${(currentMonth + 1).toString().padStart(2, '0')}.${currentYear}`;
    setDate(formattedDate);
    setLessonNumber(date);

  };

  const changeMonth = (increment) => {
    const newMonth = currentMonth + increment;
    if (newMonth < 0) {
      setCurrentYear(currentYear - 1);
      setCurrentMonth(11);
    } else if (newMonth > 11) {
      setCurrentYear(currentYear + 1);
      setCurrentMonth(0);
    } else {
      setCurrentMonth(newMonth);
    }
    setSelectedDay(null);
  };

  return (
    <div className={styles.calendar__container}>
      <div className={styles.calendar__header}>
        <button onClick={() => setCurrentDate(new Date())} className={styles.calendar__today__button}>
          Сегодня: {new Date().toLocaleDateString('ru-RU')}
        </button>
        <div className={styles.calendar__navigation}>
          <MdOutlineKeyboardArrowLeft className={styles.calendar__navigation__arrow} onClick={() => changeMonth(-1)} />
          <span className={styles.calendar__navigation__month}>{monthArray[currentMonth]} {currentYear}</span>
          <MdOutlineKeyboardArrowRight className={styles.calendar__navigation__arrow} onClick={() => changeMonth(1)} />
        </div>
      </div>
      <div className={styles.calendar__week__days}>
        {weekArray.map((day, index) => (
          <div key={index} className={styles.calendar__week_day}>
            {day}
          </div>
        ))}
      </div>
      <div className={styles.calendar__days}>
        {calendarDays}
      </div>
    </div>
  );
};

export default Calendar;
