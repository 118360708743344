import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import styles from './AllGroups.module.css';
import { IoIosArrowDown } from 'react-icons/io';

function AllGroups({ getNewInfo }) {
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [showCourseSelection, setShowCourseSelection] = useState(false);
    const [showGroupSelection, setShowGroupSelection] = useState(false);

    const { allGroups } = useSelector(state => state.group);

    const allGroupsRef = useRef(null);

    const handleCourseSelection = () => {

        if (showCourseSelection || showGroupSelection) {
            setShowCourseSelection(false);
            setShowGroupSelection(false);
        } else {
            setShowCourseSelection(true);
            setShowGroupSelection(false);
        }
    };

    const handleCourseChange = (course) => {
        setSelectedCourse(course);
        setShowGroupSelection(true);
    };

    const handleChange = (group) => {
        localStorage.setItem('lastSchedule', group.name);
        getNewInfo(group.name, localStorage.getItem('lastLesson') || "Иностранный язык");
        handleCourseSelection();
    };

    function sortGroupsByNameAndNumber(groups) {
        return groups.sort((a, b) => {
            const matchA = a.name.match(/([а-яА-Яa-zA-Z\s]+)-\s*(\d+)/);
            const matchB = b.name.match(/([а-яА-Яa-zA-Z\s]+)-\s*(\d+)/);

            if (matchA && matchB) {
                const nameCompare = matchA[1].localeCompare(matchB[1], 'ru', { sensitivity: 'base' });
                if (nameCompare !== 0) return nameCompare;

                return parseInt(matchA[2], 10) - parseInt(matchB[2], 10);
            }

            return 0;
        });
    }


    const renderCourseSelection = () => {
        if (!showCourseSelection || !allGroups) return null;

        return (
            <div className={styles.selectCourse} ref={allGroupsRef}>
                <h3 className={styles.course__title}>Выберите курс:</h3>
                <ul className={styles.course__list}>
                    {Object.keys(allGroups).map((key) => (
                        <li className={styles.course__item} key={key} onClick={() => handleCourseChange(key)}>
                            Курс {key}
                        </li>
                    ))}
                </ul>
            </div>
        );
    };

    const renderGroupSelection = () => {
        if (!showGroupSelection || !selectedCourse || !allGroups[selectedCourse]) return null;

        return (
            <div className={styles.selectGroup} ref={allGroupsRef}>
                <h3 className={styles.course__title}>Вы выбрали {selectedCourse} курс</h3>
                <ul className={styles.course__list}>
                    {sortGroupsByNameAndNumber(allGroups[selectedCourse]).map((group, index) => (
                        <li className={styles.course__item} key={index} onClick={() => handleChange(group)}>
                            {group.name}
                        </li>
                    ))}
                </ul>

            </div>
        );
    };

    return (
        <div className={styles.group__container}>
            <button className={styles.button} onClick={() => handleCourseSelection()}>
                <IoIosArrowDown />
            </button>

            {renderCourseSelection()}
            {renderGroupSelection()}
        </div>
    );
}

export default AllGroups;
