import React from 'react'
import styles from './Updates.module.css'


function Updates() {
    return (
        <section className={styles.update__section}>

            <div className={styles.update__header}>
                <h2 className={styles.update__title}>Обновление 1.12</h2>
            </div>

            <div className={styles.update__content}>

                <h3 className={styles.update__subtitle}>Обновление журнала</h3>
                <div className={styles.update__text}>
                    <p>Добавлена функция для переключения по месяцам</p>
                    <p>Исправлена ошибка с выбором предмета</p>
                    <p>Добавлена возможность ставить: n,N,н,Н для пропусков</p>
                    <p>Исправлен баг с темами в информации об уроке</p>
                    <p>Исправлен баг с парами</p>
                    <p>Исправлен баг с исчезновением наводящих линий</p>
                    <p>Рядом с датой теперь отображается номер пары</p>
                </div>
                <h3 className={styles.update__subtitle}>Обновление КТП</h3>
                <div className={styles.update__text}>
                    <p>Добавлена вкладка КТП</p>
                    <p>Исправлено отображение тем в календаре   </p>
                    <p>Добавлены переключатели между предметами и группами</p>
                    <p>Добавлена надпись "Если данные не отображаются, пожалуйста, выберите день, над которым виден подсвеченный кружок."</p>
                </div>

                <h3 className={styles.update__subtitle}>Обновление Расписания</h3>
                <div className={styles.update__text}>
                    <p>Теперь смена пары происходит через модальное окно</p>
                    <p>Добавлена возмодность изменения кабинета и преподавателя</p>
                    <p>Исправлено отображение информации в ячейках таблицы</p>
                    <p>Исправлено отображение расписания преподавателей</p>
                </div>

                <h3 className={styles.update__subtitle}>Обновление Статистики</h3>
                <div className={styles.update__text}>
                    <p>Добавлена вкладка Статистика</p>
                    <p>Пофикшен баг с подсчётом данных</p>
                </div>

                <h3 className={styles.update__subtitle}>Преподаватели</h3>
                <div className={styles.update__text}>
                    <p>Отложено на будущее</p>
                </div>

                <h3 className={styles.update__subtitle}>Студенты</h3>
                <div className={styles.update__text}>
                    <p>Отложено на будущее</p>
                </div>
            </div>

        </section>
    )
}

export default Updates